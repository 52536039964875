<template>
  <div class="apartment-config">
    <app-header v-if="title" :title="title"></app-header>
    <div class="content">
      <div class="content-header">
        <span>房源类型：</span>
        <div class="content-header-type">学校</div>
      </div>
      <div class="content-steps">
        <el-steps :active="active">
          <el-step title="房源配置"></el-step>
          <el-step title="房型配置"></el-step>
          <el-step title="房间配置"></el-step>
        </el-steps>
      </div>
      <!-- 房源配置 -->
      <div class="content-form" v-if="active == 1">
        <h1>房源表单配置</h1>
        <el-form  ref="form1" :model="houseForm">
          <div style="display: flex; justify-content: space-around">
            <div class="content-form-left">
              <div style="display: flex;justify-content: space-between;padding-left: 38px;padding-bottom: 10px;padding-right: 10px;color: #999;">
                <span>显示项</span>
                <span>是否显示</span>
                <span>是否必填</span>
              </div>
              <el-form-item v-for="(item,index) in houseForm.leftConfig" :key="index" label-width="120px" :label="item.name">
                <el-select
                  class="row-width"
                  v-model="item.display"
                  placeholder="请选择"
                  disabled
                >
                  <el-option
                    v-for="item in showList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  class="row-width"
                  v-model="item.fill"
                  placeholder="请选择"
                  disabled
                >
                  <el-option
                    v-for="item in requiredList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="content-form-right">
                <div style="display: flex;justify-content: space-between;padding-left: 38px;padding-bottom: 10px;padding-right: 10px;color: #999;">
                <span>显示项</span>
                <span>是否显示</span>
                <span>是否必填</span>
              </div>
              <el-form-item v-for="(item,index) in houseForm.rightConfig" :key="index" label-width="120px" :label="item.name">
                <el-select
                  class="row-width"
                  v-model="item.display"
                  placeholder="请选择"
                  disabled
                >
                  <el-option
                    v-for="item in showList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  class="row-width"
                  v-model="item.fill"
                  placeholder="请选择"
                  disabled
                >
                  <el-option
                    v-for="item in requiredList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <!-- 房型配置 -->
      <div class="content-form" v-if="active == 2">
        <h1>房型表单配置</h1>
        <el-form  ref="form2" :model="houseTypeForm">
            <div class="content-form-top"> 
                <div style="display: flex; justify-content: space-around">
                    <div class="content-form-left">
                    <div style="display: flex;justify-content: space-between;padding-left: 38px;padding-bottom: 10px;padding-right: 10px;color: #999;">
                        <span>显示项</span>
                        <span>是否显示</span>
                        <span>是否必填</span>
                    </div>
                    <el-form-item v-for="(item,index) in houseTypeForm.leftConfig" :key="index" label-width="120px" :label="item.name">
                        <el-select
                        class="row-width"
                        v-model="item.display"
                        placeholder="请选择"
                        disabled
                        >
                        <el-option
                            v-for="item in showList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                        </el-select>
                        <el-select
                        class="row-width"
                        v-model="item.fill"
                        placeholder="请选择"
                        disabled
                        >
                        <el-option
                            v-for="item in requiredList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                        </el-select>
                    </el-form-item>
                    </div>
                    <div class="content-form-right">
                        <div style="display: flex;justify-content: space-between;padding-left: 38px;padding-bottom: 10px;padding-right: 10px;color: #999;">
                        <span>显示项</span>
                        <span>是否显示</span>
                        <span>是否必填</span>
                    </div>
                    <el-form-item v-for="(item,index) in houseTypeForm.rightConfig" :key="index" label-width="120px" :label="item.name">
                        <el-select
                        class="row-width"
                        v-model="item.display"
                        placeholder="请选择"
                        disabled
                        >
                        <el-option
                            v-for="item in showList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                        </el-select>
                        <el-select
                        class="row-width"
                        v-model="item.fill"
                        placeholder="请选择"
                        disabled
                        >
                        <el-option
                            v-for="item in requiredList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                        </el-select>
                    </el-form-item>
                    </div>
                </div>
            </div>
            <div class="content-form-bottom">
                <h1>默认房型</h1>
                <span style="padding-left: 80px;padding-right: 10px;">房源下的房间是否支持自动匹配系统默认房型:</span>
                <el-radio v-model="typeRadio" label="1" disabled>是</el-radio>
                <el-radio v-model="typeRadio" label="0" disabled>否</el-radio>
            </div>
          
        </el-form>
      </div>
      <!-- 房间配置 -->
      <div class="content-form" v-if="active == 3">
        <h1>房间表单配置</h1>
        <el-form  ref="form3" :model="roomForm">
            <div class="content-form-top"> 
                <div style="display: flex; justify-content: space-around">
                    <div class="content-form-left">
                    <div style="display: flex;justify-content: space-between;padding-left: 38px;padding-bottom: 10px;padding-right: 10px;color: #999;">
                        <span>显示项</span>
                        <span>是否显示</span>
                        <span>是否必填</span>
                    </div>
                    <el-form-item v-for="(item,index) in roomForm.leftRoomConfig" :key="index" label-width="120px" :label="item.name">
                        <el-select
                        class="row-width"
                        v-model="item.display"
                        placeholder="请选择"
                        disabled
                        >
                        <el-option
                            v-for="item in showList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                        </el-select>
                        <el-select
                        class="row-width"
                        v-model="item.fill"
                        placeholder="请选择"
                        disabled
                        >
                        <el-option
                            v-for="item in requiredList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                        </el-select>
                    </el-form-item>
                    </div>
                    <div class="content-form-right">
                        <div style="display: flex;justify-content: space-between;padding-left: 38px;padding-bottom: 10px;padding-right: 10px;color: #999;">
                        <span>显示项</span>
                        <span>是否显示</span>
                        <span>是否必填</span>
                    </div>
                    <el-form-item v-for="(item,index) in roomForm.rightRoomConfig" :key="index" label-width="120px" :label="item.name">
                        <el-select
                        class="row-width"
                        v-model="item.display"
                        placeholder="请选择"
                        disabled
                        >
                        <el-option
                            v-for="item in showList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                        </el-select>
                        <el-select
                        class="row-width"
                        v-model="item.fill"
                        placeholder="请选择"
                        disabled
                        >
                        <el-option
                            v-for="item in requiredList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                        </el-select>
                    </el-form-item>
                    </div>
                </div>
            </div>
            <div class="content-form-bottom">
                <h1>租赁模式</h1>
                <div style="padding-left: 100px;padding-bottom: 18px;">
                  <el-checkbox v-model="checked1" disabled>长租</el-checkbox>
                  <el-checkbox v-model="checked2" disabled>排房</el-checkbox>
                </div>
            </div>
            <div class="content-form-top"> 
                <div style="display: flex; justify-content: space-around">
                    <div class="content-form-left">
                    <div style="display: flex;justify-content: space-between;padding-left: 38px;padding-bottom: 10px;padding-right: 10px;color: #999;">
                        <span>显示项</span>
                        <span>是否显示</span>
                        <span>是否必填</span>
                    </div>
                    <el-form-item v-for="(item,index) in roomForm.leftRentalConfig" :key="index" label-width="120px" :label="item.name">
                        <el-select
                        class="row-width"
                        v-model="item.display"
                        placeholder="请选择"
                        disabled
                        >
                        <el-option
                            v-for="item in showList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                        </el-select>
                        <el-select
                        class="row-width"
                        v-model="item.fill"
                        placeholder="请选择"
                        disabled
                        >
                        <el-option
                            v-for="item in requiredList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                        </el-select>
                    </el-form-item>
                  
                    </div>
                    <div class="content-form-right">
                        <div style="display: flex;justify-content: space-between;padding-left: 38px;padding-bottom: 10px;padding-right: 10px;color: #999;">
                        <span>显示项</span>
                        <span>是否显示</span>
                        <span>是否必填</span>
                    </div>
                    <el-form-item v-for="(item,index) in roomForm.rightRentalConfig" :key="index" label-width="120px" :label="item.name">
                        <el-select
                        class="row-width"
                        v-model="item.display"
                        placeholder="请选择"
                        disabled
                        >
                        <el-option
                            v-for="item in showList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                        </el-select>
                        <el-select
                        class="row-width"
                        v-model="item.fill"
                        placeholder="请选择"
                        disabled
                        >
                        <el-option
                            v-for="item in requiredList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                        </el-select>
                    </el-form-item>
                
                    </div>
                </div>
            </div>
          
        </el-form>
     </div> 

      <div class="content-btn-group">
        <el-button style="margin-top: 12px" @click="before" v-if="active > 1"
          >上一步</el-button
        >
        <el-button style="margin-top: 12px" @click="next" v-if="active < 3"
          >下一步</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "房源配置",
      active: 1,
      typeRadio: "1",
      houseForm: {
        leftConfig: [],
        rightConfig: [],
      },
      houseTypeForm: {
        leftConfig: [],
        rightConfig: [],
      },
      roomForm:{
        leftRoomConfig: [],
        rightRoomConfig: [],
        leftRentalConfig: [],
        rightRentalConfig: [],
      },
      checked1: true,
      checked2: true,
      showList: [
        {
          value: 0,
          label: "不显示",
        },
        {
          value: 1,
          label: "显示",
        },
      ],
      requiredList: [
        {
          value: 0,
          label: "否",
        },
        {
          value: 1,
          label: "是",
        },
      ],
    };
  },
  activated() {
    this.active = 1;
    this.getConfig();
  },
  mounted() {},
  methods: {
    before() {
      if (this.active-- < 1) this.active = 3;
    },
    next() {
      if (this.active++ > 3) this.active = 1;
    },
    getConfig() {
      let dto = {};
      this.post('/landlord-service/house/v1/scene/config/list',dto,{
        isUseResponse: true,
      }).then(res => {
        if(res.data.code == 0) {
          let list = []
          let houseConfig = []
          let houseTypeConfig = []
          let roomConfig = []
          let roomRentalConfig = []
          res.data.data.forEach(it => {
            // 学校类型的房源：it.houseType == 7
            if(it.houseType == 7) {
               list = it.houseSceneConfigRespList || []
               list.forEach(item => {
                // 房源配置
                if(item.configKey == 'house_config') {
                  houseConfig = item.config ? JSON.parse(item.config) : []
                }
                // 房型配置
                if(item.configKey == 'roon_type_config') {
                  houseTypeConfig = item.config ? JSON.parse(item.config) : []
                }
                // 默认房型 
                if(item.configKey == 'default_room_type') {
                  this.typeRadio = item.config
                }
                // 房间表单配置
                if(item.configKey == 'roon_config') {
                  roomConfig = item.config ? JSON.parse(item.config) : []
                }
                // 租赁模式表单配置
                if(item.configKey == 'rental_config') {
                  roomRentalConfig = item.config ? JSON.parse(item.config) : []
                }
                if(item.configKey == "rental_model") {
                  item.config = item.config ? JSON.parse(item.config) : []
                  this.checked1 = item.config.long
                  this.checked2 = item.config.arrange
                }
              })
            }
          })
          // 房源配置 
          houseConfig.forEach(item => {
            item.display = item.display ? 1 : 0
            item.fill = item.fill ? 1 : 0
          })
          let houseConfigLenHalf = Math.ceil(houseConfig.length/2)
          this.houseForm.leftConfig = houseConfig.slice(0,houseConfigLenHalf)
          this.houseForm.rightConfig = houseConfig.slice(houseConfigLenHalf,houseConfig.length)

          // 房源配置
          houseTypeConfig.forEach(item => {
            item.display = item.display ? 1 : 0
            item.fill = item.fill ? 1 : 0
          })
          let houseTypeConfigLenHalf = Math.ceil(houseTypeConfig.length/2)
          this.houseTypeForm.leftConfig = houseTypeConfig.slice(0,houseTypeConfigLenHalf)
          this.houseTypeForm.rightConfig = houseTypeConfig.slice(houseTypeConfigLenHalf,houseTypeConfig.length)
          
          // 房间表单配置
          roomConfig.forEach(item => {
            item.display = item.display ? 1 : 0
            item.fill = item.fill ? 1 : 0
          })

          let roomConfigLenHalf = Math.ceil(roomConfig.length/2)
          this.roomForm.leftRoomConfig = roomConfig.slice(0,roomConfigLenHalf)
          this.roomForm.rightRoomConfig = roomConfig.slice(roomConfigLenHalf,roomConfig.length)

           // 租赁模式表单
          roomRentalConfig.forEach(item => {
            item.display = item.display ? 1 : 0
            item.fill = item.fill ? 1 : 0
          })

          let roomRentalConfigLenHalf = Math.ceil(roomRentalConfig.length/2)
          this.roomForm.leftRentalConfig = roomRentalConfig.slice(0,roomRentalConfigLenHalf)
          this.roomForm.rightRentalConfig = roomRentalConfig.slice(roomRentalConfigLenHalf,roomRentalConfig.length)


          console.log('houseConfig房源配置:',houseConfig,houseConfig.length,houseConfigLenHalf)
          console.log('houseTypeConfig房型配置:',houseTypeConfig,houseTypeConfig.length,houseTypeConfigLenHalf)
          console.log('roomConfig房间表单配置:',roomConfig,roomConfig.length,roomConfigLenHalf)
          console.log('roomRentalConfig租赁模式表单:',roomRentalConfig,roomRentalConfig.length,roomRentalConfigLenHalf)
        }
      }).catch(err => {
        console.log(err);
      })
    },

  },
};
</script>
<style lang="scss" scoped>
.apartment-config {
  font-size: 14px;
  color: #333333;
}
.content-header-type {
  display: inline-block;
  width: 80px;
  height: 32px;
  line-height: 32px;
  border-radius: 6px;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  background-color: #30bac1;
}
.content-steps {
  padding-top: 32px;
}
.content-form {
  padding: 0px 32px 0px 32px;
}

.content-btn-group {
  text-align: center;
  padding-bottom: 40px;
}

.row-width {
  width: 200px;
  display: inline-block;
  margin-right: 10px;
}

.label-width {
  width: 80px;
}
</style>