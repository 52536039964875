var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "apartment-config" },
    [
      _vm.title ? _c("app-header", { attrs: { title: _vm.title } }) : _vm._e(),
      _c("div", { staticClass: "content" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "content-steps" },
          [
            _c(
              "el-steps",
              { attrs: { active: _vm.active } },
              [
                _c("el-step", { attrs: { title: "房源配置" } }),
                _c("el-step", { attrs: { title: "房型配置" } }),
                _c("el-step", { attrs: { title: "房间配置" } })
              ],
              1
            )
          ],
          1
        ),
        _vm.active == 1
          ? _c(
              "div",
              { staticClass: "content-form" },
              [
                _c("h1", [_vm._v("房源表单配置")]),
                _c(
                  "el-form",
                  { ref: "form1", attrs: { model: _vm.houseForm } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-around"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "content-form-left" },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                  "padding-left": "38px",
                                  "padding-bottom": "10px",
                                  "padding-right": "10px",
                                  color: "#999"
                                }
                              },
                              [
                                _c("span", [_vm._v("显示项")]),
                                _c("span", [_vm._v("是否显示")]),
                                _c("span", [_vm._v("是否必填")])
                              ]
                            ),
                            _vm._l(_vm.houseForm.leftConfig, function(
                              item,
                              index
                            ) {
                              return _c(
                                "el-form-item",
                                {
                                  key: index,
                                  attrs: {
                                    "label-width": "120px",
                                    label: item.name
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "row-width",
                                      attrs: {
                                        placeholder: "请选择",
                                        disabled: ""
                                      },
                                      model: {
                                        value: item.display,
                                        callback: function($$v) {
                                          _vm.$set(item, "display", $$v)
                                        },
                                        expression: "item.display"
                                      }
                                    },
                                    _vm._l(_vm.showList, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  ),
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "row-width",
                                      attrs: {
                                        placeholder: "请选择",
                                        disabled: ""
                                      },
                                      model: {
                                        value: item.fill,
                                        callback: function($$v) {
                                          _vm.$set(item, "fill", $$v)
                                        },
                                        expression: "item.fill"
                                      }
                                    },
                                    _vm._l(_vm.requiredList, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            })
                          ],
                          2
                        ),
                        _c(
                          "div",
                          { staticClass: "content-form-right" },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                  "padding-left": "38px",
                                  "padding-bottom": "10px",
                                  "padding-right": "10px",
                                  color: "#999"
                                }
                              },
                              [
                                _c("span", [_vm._v("显示项")]),
                                _c("span", [_vm._v("是否显示")]),
                                _c("span", [_vm._v("是否必填")])
                              ]
                            ),
                            _vm._l(_vm.houseForm.rightConfig, function(
                              item,
                              index
                            ) {
                              return _c(
                                "el-form-item",
                                {
                                  key: index,
                                  attrs: {
                                    "label-width": "120px",
                                    label: item.name
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "row-width",
                                      attrs: {
                                        placeholder: "请选择",
                                        disabled: ""
                                      },
                                      model: {
                                        value: item.display,
                                        callback: function($$v) {
                                          _vm.$set(item, "display", $$v)
                                        },
                                        expression: "item.display"
                                      }
                                    },
                                    _vm._l(_vm.showList, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  ),
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "row-width",
                                      attrs: {
                                        placeholder: "请选择",
                                        disabled: ""
                                      },
                                      model: {
                                        value: item.fill,
                                        callback: function($$v) {
                                          _vm.$set(item, "fill", $$v)
                                        },
                                        expression: "item.fill"
                                      }
                                    },
                                    _vm._l(_vm.requiredList, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.active == 2
          ? _c(
              "div",
              { staticClass: "content-form" },
              [
                _c("h1", [_vm._v("房型表单配置")]),
                _c(
                  "el-form",
                  { ref: "form2", attrs: { model: _vm.houseTypeForm } },
                  [
                    _c("div", { staticClass: "content-form-top" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-around"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "content-form-left" },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-between",
                                    "padding-left": "38px",
                                    "padding-bottom": "10px",
                                    "padding-right": "10px",
                                    color: "#999"
                                  }
                                },
                                [
                                  _c("span", [_vm._v("显示项")]),
                                  _c("span", [_vm._v("是否显示")]),
                                  _c("span", [_vm._v("是否必填")])
                                ]
                              ),
                              _vm._l(_vm.houseTypeForm.leftConfig, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "el-form-item",
                                  {
                                    key: index,
                                    attrs: {
                                      "label-width": "120px",
                                      label: item.name
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "row-width",
                                        attrs: {
                                          placeholder: "请选择",
                                          disabled: ""
                                        },
                                        model: {
                                          value: item.display,
                                          callback: function($$v) {
                                            _vm.$set(item, "display", $$v)
                                          },
                                          expression: "item.display"
                                        }
                                      },
                                      _vm._l(_vm.showList, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "row-width",
                                        attrs: {
                                          placeholder: "请选择",
                                          disabled: ""
                                        },
                                        model: {
                                          value: item.fill,
                                          callback: function($$v) {
                                            _vm.$set(item, "fill", $$v)
                                          },
                                          expression: "item.fill"
                                        }
                                      },
                                      _vm._l(_vm.requiredList, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          ),
                          _c(
                            "div",
                            { staticClass: "content-form-right" },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-between",
                                    "padding-left": "38px",
                                    "padding-bottom": "10px",
                                    "padding-right": "10px",
                                    color: "#999"
                                  }
                                },
                                [
                                  _c("span", [_vm._v("显示项")]),
                                  _c("span", [_vm._v("是否显示")]),
                                  _c("span", [_vm._v("是否必填")])
                                ]
                              ),
                              _vm._l(_vm.houseTypeForm.rightConfig, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "el-form-item",
                                  {
                                    key: index,
                                    attrs: {
                                      "label-width": "120px",
                                      label: item.name
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "row-width",
                                        attrs: {
                                          placeholder: "请选择",
                                          disabled: ""
                                        },
                                        model: {
                                          value: item.display,
                                          callback: function($$v) {
                                            _vm.$set(item, "display", $$v)
                                          },
                                          expression: "item.display"
                                        }
                                      },
                                      _vm._l(_vm.showList, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "row-width",
                                        attrs: {
                                          placeholder: "请选择",
                                          disabled: ""
                                        },
                                        model: {
                                          value: item.fill,
                                          callback: function($$v) {
                                            _vm.$set(item, "fill", $$v)
                                          },
                                          expression: "item.fill"
                                        }
                                      },
                                      _vm._l(_vm.requiredList, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        ]
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "content-form-bottom" },
                      [
                        _c("h1", [_vm._v("默认房型")]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "padding-left": "80px",
                              "padding-right": "10px"
                            }
                          },
                          [_vm._v("房源下的房间是否支持自动匹配系统默认房型:")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "1", disabled: "" },
                            model: {
                              value: _vm.typeRadio,
                              callback: function($$v) {
                                _vm.typeRadio = $$v
                              },
                              expression: "typeRadio"
                            }
                          },
                          [_vm._v("是")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "0", disabled: "" },
                            model: {
                              value: _vm.typeRadio,
                              callback: function($$v) {
                                _vm.typeRadio = $$v
                              },
                              expression: "typeRadio"
                            }
                          },
                          [_vm._v("否")]
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.active == 3
          ? _c(
              "div",
              { staticClass: "content-form" },
              [
                _c("h1", [_vm._v("房间表单配置")]),
                _c(
                  "el-form",
                  { ref: "form3", attrs: { model: _vm.roomForm } },
                  [
                    _c("div", { staticClass: "content-form-top" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-around"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "content-form-left" },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-between",
                                    "padding-left": "38px",
                                    "padding-bottom": "10px",
                                    "padding-right": "10px",
                                    color: "#999"
                                  }
                                },
                                [
                                  _c("span", [_vm._v("显示项")]),
                                  _c("span", [_vm._v("是否显示")]),
                                  _c("span", [_vm._v("是否必填")])
                                ]
                              ),
                              _vm._l(_vm.roomForm.leftRoomConfig, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "el-form-item",
                                  {
                                    key: index,
                                    attrs: {
                                      "label-width": "120px",
                                      label: item.name
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "row-width",
                                        attrs: {
                                          placeholder: "请选择",
                                          disabled: ""
                                        },
                                        model: {
                                          value: item.display,
                                          callback: function($$v) {
                                            _vm.$set(item, "display", $$v)
                                          },
                                          expression: "item.display"
                                        }
                                      },
                                      _vm._l(_vm.showList, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "row-width",
                                        attrs: {
                                          placeholder: "请选择",
                                          disabled: ""
                                        },
                                        model: {
                                          value: item.fill,
                                          callback: function($$v) {
                                            _vm.$set(item, "fill", $$v)
                                          },
                                          expression: "item.fill"
                                        }
                                      },
                                      _vm._l(_vm.requiredList, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          ),
                          _c(
                            "div",
                            { staticClass: "content-form-right" },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-between",
                                    "padding-left": "38px",
                                    "padding-bottom": "10px",
                                    "padding-right": "10px",
                                    color: "#999"
                                  }
                                },
                                [
                                  _c("span", [_vm._v("显示项")]),
                                  _c("span", [_vm._v("是否显示")]),
                                  _c("span", [_vm._v("是否必填")])
                                ]
                              ),
                              _vm._l(_vm.roomForm.rightRoomConfig, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "el-form-item",
                                  {
                                    key: index,
                                    attrs: {
                                      "label-width": "120px",
                                      label: item.name
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "row-width",
                                        attrs: {
                                          placeholder: "请选择",
                                          disabled: ""
                                        },
                                        model: {
                                          value: item.display,
                                          callback: function($$v) {
                                            _vm.$set(item, "display", $$v)
                                          },
                                          expression: "item.display"
                                        }
                                      },
                                      _vm._l(_vm.showList, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "row-width",
                                        attrs: {
                                          placeholder: "请选择",
                                          disabled: ""
                                        },
                                        model: {
                                          value: item.fill,
                                          callback: function($$v) {
                                            _vm.$set(item, "fill", $$v)
                                          },
                                          expression: "item.fill"
                                        }
                                      },
                                      _vm._l(_vm.requiredList, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "content-form-bottom" }, [
                      _c("h1", [_vm._v("租赁模式")]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "padding-left": "100px",
                            "padding-bottom": "18px"
                          }
                        },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.checked1,
                                callback: function($$v) {
                                  _vm.checked1 = $$v
                                },
                                expression: "checked1"
                              }
                            },
                            [_vm._v("长租")]
                          ),
                          _c(
                            "el-checkbox",
                            {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.checked2,
                                callback: function($$v) {
                                  _vm.checked2 = $$v
                                },
                                expression: "checked2"
                              }
                            },
                            [_vm._v("排房")]
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "content-form-top" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-around"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "content-form-left" },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-between",
                                    "padding-left": "38px",
                                    "padding-bottom": "10px",
                                    "padding-right": "10px",
                                    color: "#999"
                                  }
                                },
                                [
                                  _c("span", [_vm._v("显示项")]),
                                  _c("span", [_vm._v("是否显示")]),
                                  _c("span", [_vm._v("是否必填")])
                                ]
                              ),
                              _vm._l(_vm.roomForm.leftRentalConfig, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "el-form-item",
                                  {
                                    key: index,
                                    attrs: {
                                      "label-width": "120px",
                                      label: item.name
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "row-width",
                                        attrs: {
                                          placeholder: "请选择",
                                          disabled: ""
                                        },
                                        model: {
                                          value: item.display,
                                          callback: function($$v) {
                                            _vm.$set(item, "display", $$v)
                                          },
                                          expression: "item.display"
                                        }
                                      },
                                      _vm._l(_vm.showList, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "row-width",
                                        attrs: {
                                          placeholder: "请选择",
                                          disabled: ""
                                        },
                                        model: {
                                          value: item.fill,
                                          callback: function($$v) {
                                            _vm.$set(item, "fill", $$v)
                                          },
                                          expression: "item.fill"
                                        }
                                      },
                                      _vm._l(_vm.requiredList, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          ),
                          _c(
                            "div",
                            { staticClass: "content-form-right" },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-between",
                                    "padding-left": "38px",
                                    "padding-bottom": "10px",
                                    "padding-right": "10px",
                                    color: "#999"
                                  }
                                },
                                [
                                  _c("span", [_vm._v("显示项")]),
                                  _c("span", [_vm._v("是否显示")]),
                                  _c("span", [_vm._v("是否必填")])
                                ]
                              ),
                              _vm._l(_vm.roomForm.rightRentalConfig, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "el-form-item",
                                  {
                                    key: index,
                                    attrs: {
                                      "label-width": "120px",
                                      label: item.name
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "row-width",
                                        attrs: {
                                          placeholder: "请选择",
                                          disabled: ""
                                        },
                                        model: {
                                          value: item.display,
                                          callback: function($$v) {
                                            _vm.$set(item, "display", $$v)
                                          },
                                          expression: "item.display"
                                        }
                                      },
                                      _vm._l(_vm.showList, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "row-width",
                                        attrs: {
                                          placeholder: "请选择",
                                          disabled: ""
                                        },
                                        model: {
                                          value: item.fill,
                                          callback: function($$v) {
                                            _vm.$set(item, "fill", $$v)
                                          },
                                          expression: "item.fill"
                                        }
                                      },
                                      _vm._l(_vm.requiredList, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        ]
                      )
                    ])
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "content-btn-group" },
          [
            _vm.active > 1
              ? _c(
                  "el-button",
                  {
                    staticStyle: { "margin-top": "12px" },
                    on: { click: _vm.before }
                  },
                  [_vm._v("上一步")]
                )
              : _vm._e(),
            _vm.active < 3
              ? _c(
                  "el-button",
                  {
                    staticStyle: { "margin-top": "12px" },
                    on: { click: _vm.next }
                  },
                  [_vm._v("下一步")]
                )
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content-header" }, [
      _c("span", [_vm._v("房源类型：")]),
      _c("div", { staticClass: "content-header-type" }, [_vm._v("学校")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }